const custom = {
    50: '#66a7c5',
    100: '#66a7c5',
    200: '#66a7c5',
    300: '#66a7c5',
    400: '#66a7c5',
    500: '#66a7c5',
    600: '#66a7c5',
    700: '#66a7c5',
    800: '#66a7c5',
    900: '#66a7c5',
    A100: '#66a7c5',
    A200: '#66a7c5',
    A400: '#66a7c5',
    A700: '#66a7c5'
}

export default custom;